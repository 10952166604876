const protocol = window.location.protocol;
const clientHost = window.location.hostname;
const clientPort = window.location.port;

const localHostPath = clientHost.includes("localhost") 
|| clientHost.includes("127.0.0.1") 
? "/olivercomposer_react" : "";
const serverPort = clientHost.includes("localhost") 
|| clientHost.includes("127.0.0.1") ? 80 : "";

const cBaseUrl = `${protocol}//${clientHost}:${clientPort}${localHostPath}`;
const sBaseUrl = `${protocol}//${clientHost}:${serverPort}${localHostPath}`;

export {
    protocol, clientHost, 
    clientPort,serverPort, 
    cBaseUrl, sBaseUrl,
    localHostPath
};