function Footer() {
    return(
        <footer className="bg-dark-grey text-center p-md">
            <span className="color-primary">
                Olivér Kovács, composer <span className="color-white">{new Date().getFullYear()}</span>
            </span>
        </footer>
    );
}

export default Footer;