import { Link } from "react-router-dom";
import Footer from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Services() {
    return (
        <>
            <div className="container-lg text-justify margin-auto mb-lg p-lg">
                <h1>Services</h1>
                <div className="underline"></div>
            </div>

            <div className="bg-primary p-lg">
                <div className="container-lg text-justify margin-auto">
                    <h2>Game Music</h2>
                    <p>
                        Welcome to my professional music composition services! I specialize
                        in creating captivating soundtracks that 
                        <strong>enhance your projects significantly</strong>.
                        My focus lies in crafting music tailored <strong>specifically
                            for video games</strong>. Video game music holds
                        unique requirements. Notably, tracks must<strong>
                            be seamlessly loopable</strong> and cater to various
                        in-game events such as <strong>victories</strong>,
                        <strong>defeats</strong>, or <strong>important discoveries</strong>.
                        
                        <div className="mt-lg">
                            <Link className="white-link-secondary font-18" to="/contact">
                                <FontAwesomeIcon className="mr-sm"
                                icon="fa-solid fa-envelope" />
                                React out to me now
                            </Link>
                        </div>
                    </p>
                </div>
            </div>

            <div className="bg-secondary p-lg">
                <div className="container-lg text-justify margin-auto">
                    <h2 className="color-primary">Film Soundtrack</h2>
                    <p className="color-white">
                        Elevate your cinematic experience with bespoke <strong>film soundtracks</strong>.
                        Dive into a world where <strong>music harmonizes with imagery</strong>,
                        enhancing every scene. Our compositions <strong>immerse audiences</strong>
                        in the story, <strong>amplifying emotions</strong> and <strong>capturing
                            the essence</strong> of your narrative. Let us <strong>compose</strong>
                        the perfect soundtrack for your film, <strong>bringing your vision to life</strong>
                        with every note.
                        
                        <div className="mt-lg">
                            <Link className="white-link-primary" to="/contact">
                                <FontAwesomeIcon className="mr-sm"
                                icon="fa-solid fa-envelope" />
                                React out to me now
                            </Link>
                        </div>
                        
                    </p>
                </div>
            </div>

            <div className="bg-primary p-lg">
                <div className="container-lg text-justify margin-auto">
                    <h2>Sound Logo</h2>
                    <p>
                        Elevate your brand's identity with our custom sound logo solutions. A <b>sound logo</b> is
                        more than just an audio signature it's the sonic embodiment of your <b>brand's essence</b>.
                        With my expertise in audio branding, I craft distinctive sound logos that leave
                        a lasting impression on your audience. Let me create a unique sonic identity for
                        your brand, ensuring that every sound reinforces your message and resonates with
                        impact.

                        <div className="mt-lg">
                            <Link className="white-link-secondary" to="/contact">
                                <FontAwesomeIcon className="mr-sm"
                                icon="fa-solid fa-envelope" />
                                React out to me now
                            </Link>
                        </div>
                    </p>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default Services;