import { useState } from "react";
import MessageBox from "./MessageBox";
import { cBaseUrl, sBaseUrl } from "../app/Url";

function ContactForm() {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState(0);
    const [message, setMessage] = useState("");
    const [errorMessages, setErrorMessages] = useState([]);
    const [showMessageBox, setShowMessageBox] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            name: fullName,
            email: email,
            subject: subject,
            message: message
        };

        try {
            const response = await fetch(`${sBaseUrl}/email.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                mode:"cors",
                body: JSON.stringify(data)
            });

            const rsp = await response.text();
            console.log(rsp);
            const responseData = JSON.parse(rsp);
            console.log(responseData);

            if (response.ok) {
                // Sikeres üzenet küldés esetén
                // Töröld az űrlap tartalmát
                setFullName("");
                setEmail("");
                setSubject(0);
                setMessage("");
                setShowMessageBox(true); // Megjelenítjük a sikeres üzenetet
                setErrorMessages(responseData.messages);
                setShowMessageBox(true);
            } else {
                // Hibás válasz esetén
                setErrorMessages(responseData.messages);
                setShowMessageBox(true);
            }
        } catch (error) {
            console.error('Hiba történt:', error);
        }
    };

    return (
        <div className="contact-box pb-md">
            <form onSubmit={handleSubmit}>
                <div className="row text-center">
                    <div className="col-lg-6 col-md-6 p-lg">
                        <div className="transparent-sec-box p-lg rounded-md">
                            <h3>Full name</h3>
                            <input
                                className="input-md input-primary rounded-sm width-90p center-input"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                            />

                            <h3>Email address</h3>
                            <input
                                className="input-md input-primary rounded-sm width-90p center-input"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />

                            <h3>Subject</h3>
                            <select
                                className="input-md input-primary rounded-sm width-90p center-input"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                            >
                                <option value="0">select a subject!</option>
                                <option value="Film soundtrack">Film soundtrack</option>
                                <option value="Game soundtrack">Game soundtrack</option>
                                <option value="Sound logo">Sound logo</option>
                                <option value="I'm a singer/instrumentalist">I'm a singer/instrumentalist</option>
                                <option value="Track for media sync">Track for media sync</option>
                                <option value="General inquiry">General inquiry</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 p-lg">
                        <div className="transparent-sec-box p-lg rounded-md">
                            <h3>Message</h3>
                            <textarea
                                className="input-md input-primary rounded-sm width-90p center-input textarea"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <button type="submit" className="input-lg center-input btn-secondary rounded-sm font-16">
                    Send
                </button>
            </form>
            <MessageBox
                display={showMessageBox}
                setDisplay={setShowMessageBox}
                messages={errorMessages}
                buttons={[{ text: "OK", cb: () => setShowMessageBox(false), icon: "fa-solid fa-square-check" }]}
            />
        </div>
    );
}

export default ContactForm;