import { Link, Outlet } from "react-router-dom";
import React, { useState, useEffect } from "react";
import SelectMenu from "../app/SelectMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Nav() {
    const [scrolled, setScrolled] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.pageYOffset;
            if (offset > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            <div className="menu-icon" onClick={toggleMenu}>
                <FontAwesomeIcon icon={menuOpen ? "times" : "bars"} />
            </div>
            <nav className={`${scrolled ? "transparent-dark-grey" : ""} ${menuOpen ? 'menu-show' : 'menu-hide'}`}>
                <div className="container-lg logo-ul margin-auto">
                    <div className="logo-holder">
                        <Link to="/">
                            <span className="color-primary">Olivér Kovács, </span>
                            <span className="color-white">composer</span>
                        </Link>
                    </div>
                    
                    <ul className={`container-lg`}>
                        <li className={SelectMenu("/")}>
                            <Link onClick={()=>setMenuOpen(false)} to="/">About me</Link>
                        </li>
                        <li className={SelectMenu("/services")}>
                            <Link onClick={()=>setMenuOpen(false)} to="/services">Services</Link>
                        </li>
                        <li className={SelectMenu("/portfolio")}>
                            <Link onClick={()=>setMenuOpen(false)} to="/portfolio">Portfolio</Link>
                        </li>
                        <li className={SelectMenu("/contact")}>
                            <Link onClick={()=>setMenuOpen(false)} to="/contact">Contact</Link>
                        </li>
                    </ul>
                </div>

            </nav>
            <header>
                <div>
                    <div className="logo-holder-mobile">
                        <span className="color-primary-lighter">Olivér Kovács, </span>
                        <span className="color-white">composer</span>
                    </div>
                    <div className="header-services">
                        <div className="service-separator right-border">
                            <FontAwesomeIcon className="mr-sm color-primary"
                                icon="fa-solid fa-music" />
                            Soundtrack
                        </div>
                        <div className="service-separator right-border">Game Music</div>
                        <div className="service-separator">
                            Sound Logo
                            <FontAwesomeIcon className="ml-sm color-primary mirror"
                                icon="fa-solid fa-music" />
                        </div>
                    </div>
                </div>
            </header>
            <Outlet />
        </>
    );
}

export default Nav;
