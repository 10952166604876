import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactForm from "./ContactForm";
import Footer from "./Footer";

function Contact() {
    return (
        <>
            <div className="container-lg margin-auto p-lg">
                <h1>Contact</h1>
                <div className="underline mb-lg"></div>

                <div className="row text-center mb-lg">
                    <div className="box-secondary p-lg col-lg-6 col-md-6 grid-table-cell">
                        <h3 className="color-white">Contact</h3>

                        <h4 className="color-primary-lighter t-transform-none">
                            <FontAwesomeIcon className="mr-sm"
                                icon="fa-solid fa-mobile-screen-button" />
                            <a href="tel:+36703050928">+3670 305 0928</a>
                        </h4>

                        <h4 className="color-primary-lighter t-transform-none">
                            <FontAwesomeIcon className="mr-sm"
                                icon="fa-solid fa-envelope" />
                            <a href="mailto:info@oliverkovacs.eu">info@oliverkovacs.eu</a>
                        </h4>
                    </div>

                    <div className="box-secondary p-lg col-lg-6 col-md-6 grid-table-cell">
                        <h3 className="color-white">Social Media</h3>

                        <h4 className="color-primary-lighter t-transform-none">
                            <FontAwesomeIcon className="mr-sm"
                                icon="fa-brands fa-square-facebook" />
                            <a target="_blank" href="https://www.facebook.com/olivercomposer">Facebook</a>
                        </h4>

                        <h4 className="color-primary-lighter t-transform-none">
                            <FontAwesomeIcon className="mr-sm"
                                icon="fa-brands fa-square-youtube" />
                            <a target="_blank" href="https://youtube.com/@olivercomposer">YouTube</a>
                        </h4>

                        <h4 className="color-primary-lighter t-transform-none">
                            <FontAwesomeIcon className="mr-sm"
                                icon="fa-brands fa-soundcloud" />
                            <a target="_blank" href="https://soundcloud.com/olivercomposer">SoundCloud</a>
                        </h4>
                    </div>
                </div>

                <ContactForm />
            </div>

            <Footer/>
        </>
    );
}

export default Contact;