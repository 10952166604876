import Footer from "./Footer";
import PortfolioElement from "./PortfolioElement";

function Portfolio() {
    const beanStalker = `The branches reach up to the sky, and the beanstalk provides a link between Earth and the Upper world. Every continent and every climate rises from the underworld. An endless horde of creatures descend from the sky and crawl out from the depths. You must hunt and adapt to a new way of vertical life. Become a Stalker of this New Realm. Use your Blade Slammer and turn into more than a hunter of this new world.
    Evolve, create, develop to be the most skilled among all, and defeat the spirit of the tree on top.`;

    return(
        <>
            <div className="container-lg margin-auto bg-v-light-grey p-lg">
                <h1>Portfolio</h1>
                <div className="underline mb-lg"></div>

                <PortfolioElement 
                title="Tales of the Laser Knights"
                desc="Our young hero Zak goes on a journey to find the Ancient Orbs - really old, really mighty alien technology - spread across all 5 known planets in the galaxy!"
                img={require("../images/Portfolio/totlk_wallpaper.png")}/>

                <PortfolioElement 
                title="Bean Stalker"
                desc={beanStalker}
                img={require("../images/Portfolio/bean_stalker_wallpaper.jpg")}/>
            </div>

            <Footer/>
        </>
    );
}

export default Portfolio;