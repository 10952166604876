import Contact from "./Contact";
import ContactForm from "./ContactForm";
import Footer from "./Footer";

function AboutMe() {

    const soundCloudStyle = {
        fontSize: '10px',
        color: '#cccccc',
        lineBreak: 'anywhere',
        wordBreak: 'normal',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif',
        fontWeight: '100',
      };
      
    const linkStyle = {
        color: '#cccccc',
        textDecoration: 'none',
    };
      
    return (
        <>
            <div className="container-lg margin-auto p-lg text-justify">
                <h1>About me</h1>
                <div className="underline mb-lg"></div>
                
                <div className="left-img">
                    <img src={require("../images/Me/me.png")}/>
                </div>
                <p>
                    I'm Olivér Kovács, media composer from Budapest. I've been composing music for 20 years
                    (since I was 14 years old). I have significant experience in the field of symphonic
                    music and a strong expertise with both electronic and ethnic instruments. During my
                    career, I worked together with local filmmakers and game developers.
                </p>
                <p>
                    One of my most important musical projects (so far) is the soundtrack of
                    Bean Stalker, an adventure VR game. I created not only the soundtrack itself
                    but also all of the trailer music.
                </p>
                <p>
                    I'm eager to collaborate with game developers and filmmakers.
                    Music is my true passion, so if you want to elevate your media product
                    with an expressive and impactful soundtrack, do not hesitate to contact me!
                </p>
            </div>

            <div className="bg-secondary p-xl text-center">
                <div className="container-lg margin-auto">
                    <h2 className="color-primary-darker">Earlier Works</h2>
                    <div className="underline mb-lg"></div>

                    <div>
                        <iframe
                            width="100%"
                            height="450"
                            scrolling="no"
                            frameBorder="no"
                            allow="autoplay"
                            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1321081408&color=%2326b9a5&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
                        ></iframe>
                        <div style={soundCloudStyle}>
                            <a
                                href="https://soundcloud.com/olivercomposer"
                                title="olivercomposer"
                                target="_blank"
                                style={linkStyle}
                            >
                                olivercomposer
                            </a>{' '}
                            ·{' '}
                            <a
                                href="https://soundcloud.com/olivercomposer/sets/best-tracks"
                                title="best tracks"
                                target="_blank"
                                style={linkStyle}
                            >
                                best tracks
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-lg margin-auto p-lg text-justify">
                <h1>Contact</h1>
                <div className="underline mb-lg"></div>

                <ContactForm/>
            </div>

            <Footer></Footer>
        </>
    );
}

export default AboutMe;