import './styles/style.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Nav from "./components/Nav";
import AboutMe from "./components/AboutMe";
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faCheckCircle, faCircleCheck, faCircleXmark, faEnvelope, faMobileScreenButton, faMusic, faSquareCheck, faTimes } 
from '@fortawesome/free-solid-svg-icons';
import { faSoundcloud, faSquareFacebook, faSquareYoutube } from '@fortawesome/free-brands-svg-icons';
import Services from './components/Services';
import { localHostPath } from './app/Url';
library.add(
    faMusic, faMobileScreenButton, 
    faEnvelope, faSquareFacebook,
    faSquareYoutube, faSoundcloud,
    faCircleXmark, faCheckCircle,
    faCircleCheck, faCircleXmark,
    faSquareCheck, faBars, faTimes
);

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Nav/>}>
                    <Route index element={<AboutMe/>}/>
                    <Route path='/services' element={<Services/>}/>
                    <Route path='/portfolio' element={<Portfolio/>}/>
                    <Route path='/contact' element={<Contact/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
