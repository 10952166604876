function PortfolioElement({img, title, desc}) {
    return(
        <div>
            <h2>{title}</h2>

            <p>
                {desc}
            </p>

            <div className="portfolio-img">
                <img src={img}/>
            </div>
        </div>
    );
}

export default PortfolioElement;